<script setup>
 const { anchorId, text, padding, backgroundColor } = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  text: {
   type: String,
   required: true,
   default: ' ',
  },
  padding: {
   type: String,
   default: 'default',
  },
  backgroundColor: {
   type: String,
   default: 'bg-gray-100',
  },
  attributes: {
   type: Object,
   default: () => ({}),
  },
 });
</script>

<template>
 <section :id="anchorId" class="block block-text" :class="[`padding-${padding}`, backgroundColor]">
  <div class="container rich-text" v-aos>
   <MessHtml :html="text" class="inner-rich-text-container" />
  </div>
 </section>
</template>
